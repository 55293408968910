import {
    handleQueryResolve,
    buildMapToField
} from '../utils';

export default function (PatientID, GenericName = '', Strength = '', extendedWhere = 'rx.RxDate > DATEADD(month, -6, GetDate())'){

    let where = 'rx.PatientID = @PatientID';
    const attributes = [{
        param: 'PatientID',
        type: 'sql.Int',
        value: PatientID
    }];

    if (GenericName) {
        where += ' AND drug.GenericName = @GenericName';
        attributes.push({
            param: 'GenericName',
            type: 'sql.VarChar(40)',
            value: GenericName
        })
    }

    if (Strength) {
        where += ' AND drug.Strength = @Strength';
        attributes.push({
            param: 'Strength',
            type: 'sql.VarChar(40)',
            value: Strength
        })
    }

    if(extendedWhere){
      where += ` AND ${extendedWhere}`;
  }

    return this.query(`
      SELECT
        rx.RxNumber as RxNumber,
        rx.RxID as RxID,
        drug.GenericName as GenericName,
        drug.Strength as RxStrength,
        rx.RxDate as RxDate,
        rx.QtyDispense as RxQtyDispense,
        rx.Days as RxDays,
        rx.Interval as RxInterval
      FROM
        Fillware.dbo.DrugRoot as drug
      INNER JOIN
        Fillware.dbo.Rx as rx ON drug.DIN = rx.DIN
      WHERE
        ${where}
      AND rx.RxDate > DATEADD(month, -6, GetDate())
      ORDER BY rx.RxDate DESC
    `, attributes)
    .then(handleQueryResolve)
    .then((records) => {
        return records.map(row => buildMapToField(row));
    });
}